import oButton from '@/objects/o-button/o-button.vue';

export default {
  name: 'select',
  components: {
    'o-button': oButton
  },
  data() {
    return {
      to: null
    };
  },
  methods: {
  }
};
